window.addEventListener('load', function () {
  // obtain plugin
  document.body.classList.add('theme_goml');
  var cc = initCookieConsent();

  // run plugin with your configuration
  cc.run({
    current_lang: 'en',
    delay: 0,
    autoclear_cookies: true,                   // default: false
    page_scripts: true,                        // default: false
    gui_options: {
      consent_modal: {
        layout: 'cloud',
        position: 'bottom center',
        trasition: 'slide',
        swap_buttons: false,
      },
      settings_modal: {
        layout: 'cloud',
        trasition: 'slide',
      }
    },
    autoclear_cookies: true,
    revision: 0,
    mode: 'opt-in',                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    // auto_language: null                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    // force_consent: false,                   // default: false
    // hide_from_bots: true,                   // default: true
    // remove_cookie_tables: false             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0

    onFirstAction: function(user_preferences, cookie){
      if(user_preferences.accept_type === 'necessary'){
        window['ga-disable-G-JZJRDS9FL8'] = true;
      }
    },

    onAccept: function (cookie) {
      // ...
      let theUserPreferences = cc.getUserPreferences();
      let theRejectedCategories = theUserPreferences.rejected_categories;

      if(theRejectedCategories.includes('analytics')) {
        window['ga-disable-G-JZJRDS9FL8'] = true;
      
      //   let cookies = document.cookie.split("; ");
      //   for (let i = 0; i < cookies.length; i++){
      //     let aCookie = cookies[i];
      //   }
      }          
    },

    onChange: function (cookie, changed_preferences) {
      let theUserPreferences = cc.getUserPreferences();
      let theRejectedCategories = theUserPreferences.rejected_categories;
    
      if (typeof(changed_preferences) !== 'undefined') {
        for(let i = 0; i < changed_preferences.length; i++){
          let prop = changed_preferences[i]; 
    
          if ( cookie.categories.includes(prop) && prop === 'analytics' ){
            window['ga-disable-G-JZJRDS9FL8'] = false;
      } else if(theRejectedCategories.includes('analytics')) {
            window['ga-disable-G-JZJRDS9FL8'] = true;
          }
        }
      }
    },
    languages: {
      'en': {
        consent_modal: {
          title: 'We use cookies!',
          description: 'Hi, Georgia ONmyLine website uses essential cookies to ensure its proper operation and analytical cookies to understand how you interact with it. <em>The latter will be set only after consent</em>. We do not use tracking cookies on this site. Selecting the &quot;Reject All&quot; button will still allow the essential cookies to be set but will block the analytical cookies from being set. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
          primary_btn: {
            text: 'Accept all',
            role: 'accept_all'              // 'accept_selected' or 'accept_all'
          },
          secondary_btn: {
            text: 'Reject all',
            role: 'accept_necessary'        // 'settings' or 'accept_necessary'
          }
        },
        settings_modal: {
          title: 'Cookie preferences',
          save_settings_btn: 'Save settings',
          accept_all_btn: 'Accept all',
          reject_all_btn: 'Reject all',
          close_btn_label: 'Close',
          cookie_table_headers: [
            {col1: 'Name'},
            {col2: 'Domain'},
            {col3: 'Expiration'},
            {col4: 'Description'}
          ],
          blocks: [
            {
              title: 'Cookie usage ', //📢
              description: 'GOML uses cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="/cookie-policy" class="cc-link">cookie policy</a>.'
            }, 
            {
              title: 'Strictly necessary cookies',
              description: 'These cookies are essential for the proper functioning of the GOML website. Without these cookies, the website would not work properly.',
              toggle: {
                value: 'necessary',
                enabled: true,
                readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
              }
            }, 
            {
              title: 'Google Analytics cookies',
              description: 'These cookies allow the website to remember the choices you have made in the past',
              toggle: {
                value: 'analytics',     // your cookie category
                enabled: false,
                readonly: false
              },
              cookie_table_headers: [
                {col1: "Name"},
                {col2: "Source"},
                {col3: "Duration"},
                {col4: "Description"},
              ],
              cookie_table: [ // an accurate list of all expected cookies is necessary for the autoclear_cookies property to work properly.
                {
                  col1: '_ga', 
                  col2: 'google.com',
                  col3: '2 years',
                  col4: 'Google Analytics provides statistics and analytical tools for search engine optimization and marketing.',
                  is_regex: true
                },
                {
                  col1: '_gid',
                  col2: 'google.com',
                  col3: '1 day',
                  col4: 'This cookie identifies your machine and is used to generate statistical data on how you use the website. This cookie expires in 1 day.',
                }
              ]
            }, 
            {
              title: 'Microsoft Clarity Cookies',
              description: 'We partner with Microsoft Clarity and Microsoft Advertising to capture how you use and interact with our website through behavioral metrics, heatmaps, and session replay to improve and market our products/services. Website usage data is captured using first and third-party cookies and other tracking technologies to determine the popularity of products/services and online activity. Additionally, we use this information for site optimization, fraud/security purposes, and advertising. For more information about how Microsoft collects and uses your data, visit the <a href="https://privacy.microsoft.com/en-US/privacystatement">Microsoft Privacy Statement</a>.',
              toggle: {
                value: 'clarity',
                enabled: false,
                readonly: false,
              },
            }, 
            // {
            //   title: 'Advertisement and Targeting cookies',
            //   description: 'We do not use advertising or targeting cookies on GOML.',
            //   // toggle: {
            //   //   value: 'targeting',
            //   //   enabled: false,
            //   //   readonly: false
            //   // },
            // }, 
            {
              title: 'More information',
              description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="mailto:ecampus@ecampus.usg.edu">email us</a>.',
            }
          ]
        }
      }
    }
  });
});