"use strict";
AOS.init({
  duration: 1000,
  once: true
})

/*	--------------------------------------------------
:: Enable bootstrap popovers - For the AOS badges that are no longer in use on cards.
-------------------------------------------------- */

$(function () {
  $('[data-toggle="popover"]').popover()
})

/*	--------------------------------------------------
:: Scroll to top button 
-------------------------------------------------- */
$(window).scroll(function () {
  var height = $(window).scrollTop();
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    $("#on-top").addClass('btn-ontop--show');
  } else {
    $("#on-top").removeClass('btn-ontop--show');
  }
});

$(document).ready(function () {
  $("#on-top").click(function (event) {
    event.preventDefault();
    $("html, body").animate({
      scrollTop: 0
    }, "slow");
    return false;
  });
});

/*	--------------------------------------------------
:: Nav
-------------------------------------------------- */

jQuery(function ($) {
  $('.droopmenu-navbar').droopmenu({
    dmOffCanvas: true,
    dmSticky: true, // Make menu sticky becomes fixed on scrolling to top of page: true or false - Default is false
    dmAnimationEffect: 'dmscale',
    dmBreakpoint: 960, //Mobile Break Point - Change it in case you change the one in CSS
    dmAnimDelay: 1200, //Menu Animation Delay for removing the animation class - helps on sychronizing the scrollbar
    dmShowDelay: 200, // Wait before showing Menu Dropdown 
    dmHideDelay: 200, // Wait before hiding Menu Dropdown 
    dmToggleSpeed: 200, // Mobile Toggle Menu speed
    dmClickIcon: false
  });
});


/*	--------------------------------------------------
:: Equal height
-------------------------------------------------- */
$('.col-equal').matchHeight({
  property: 'height'
});

/*	--------------------------------------------------
:: View more Component
-------------------------------------------------- */
$('#button-view').on('click', function () {
  $('.view-more').toggleClass('view-more--show');
  $(this).text(function (i, text) {
    return text === "View Less" ? "View More" : "View Less";
  })
});

/*--------------------------------------------------
:: Skip To Content
--------------------------------------------------*/

$(".skip").click(function (event) {

  // strip the leading hash and declare
  // the content we're skipping to
  var skipTo = "#" + this.href.split('#')[1];

  // Setting 'tabindex' to -1 takes an element out of normal 
  // tab flow but allows it to be focused via javascript
  $(skipTo).attr('tabindex', -1).on('blur focusout', function () {

    // when focus leaves this element, 
    // remove the tabindex attribute
    $(this).removeAttr('tabindex');

  }).focus(); // focus on the content container
});
